import React, { useContext, useEffect, useState } from "react";
import "./DrumConfiguratorPage.css";
import { DrumConfigurationContext } from "../../context/DrumConfigurationContext";
import { useTranslation } from "react-i18next";

const DrumConfiguratorPage = ({ machineIndex }) => {
  const {
    getDrumConfiguration,
    setDrumConfiguration,
    drumConfigurations,
  } = useContext(DrumConfigurationContext);

  const { t } = useTranslation();

  const [drumConfiguration, setLocalDrumConfiguration] = useState(
    getDrumConfiguration(machineIndex)
  );

  useEffect(() => {
    setLocalDrumConfiguration(getDrumConfiguration(machineIndex));
  }, [machineIndex, drumConfigurations, getDrumConfiguration]);

  const {
    columnsPerRow,
    mergedRows,
    mergedRowsLeft,
    mergedRowsRight,
    rows,
    columns,
    options,
  } = drumConfiguration;

  const defaultColumns = Array(rows).fill(columns);

  const getMergedGroupIndices = (rowIndex) => {
    let startIndex = rowIndex;
    while (startIndex > 0 && mergedRows[startIndex - 1]) {
      startIndex--;
    }
    let endIndex = rowIndex;
    while (endIndex < mergedRows.length && mergedRows[endIndex]) {
      endIndex++;
    }
    const indices = [];
    for (let i = startIndex; i <= endIndex; i++) {
      indices.push(i);
    }
    return indices;
  };

  const handleRowCheckboxChange = (rowIndex, event) => {
    const newMergedRows = [...mergedRows];
    const isChecked = event.target.checked;
    const newColumnsPerRow = [...columnsPerRow];

    if (rowIndex < newMergedRows.length - 1) {
      newMergedRows[rowIndex] = !isChecked;

      if (!isChecked) {
        let startIndex = rowIndex;
        while (startIndex > 0 && newMergedRows[startIndex - 1]) {
          startIndex--;
        }

        const valueToUse = newColumnsPerRow[startIndex];

        newColumnsPerRow[rowIndex + 1] = valueToUse;
      }

      const newConfig = {
        ...drumConfiguration,
        mergedRows: newMergedRows,
        columnsPerRow: newColumnsPerRow
      };

      setLocalDrumConfiguration(newConfig);
      setDrumConfiguration(machineIndex, newConfig);
    }
  };

  const handleSelectChange = (rowIndex, event) => {
    const newColumnsPerRow = [...columnsPerRow];
    const newValue = isNaN(Number(event.target.value))
      ? 60
      : Number(event.target.value);

    const groupIndices = getMergedGroupIndices(rowIndex);
    groupIndices.forEach((index) => {
      newColumnsPerRow[index] = newValue;
    });

    const newConfig = {
      ...drumConfiguration,
      columnsPerRow: newColumnsPerRow,
    };

    setLocalDrumConfiguration(newConfig);
    setDrumConfiguration(machineIndex, newConfig);
  };

  const getEffectiveColumnsPerRow = (rowIndex) => {
    let startIndex = rowIndex;
    while (startIndex > 0 && mergedRows[startIndex - 1]) {
      startIndex--;
    }
    return columnsPerRow[startIndex];
  };

  const handleCheckboxChange = (rowIndex, side, event) => {
    const newMergedRowsLeft = [...mergedRowsLeft];
    const newMergedRowsRight = [...mergedRowsRight];
    const isChecked = event.target.checked;

    if (isChecked) {
      if (side === "left" && newMergedRowsLeft[rowIndex])
        newMergedRowsLeft[rowIndex] = false;
      if (side === "right" && newMergedRowsRight[rowIndex])
        newMergedRowsRight[rowIndex] = false;
    } else {
      if (side === "left") newMergedRowsLeft[rowIndex] = true;
      if (side === "right") newMergedRowsRight[rowIndex] = true;
    }

    const newConfig = {
      ...drumConfiguration,
      mergedRowsLeft: newMergedRowsLeft,
      mergedRowsRight: newMergedRowsRight,
    };

    setLocalDrumConfiguration(newConfig);
    setDrumConfiguration(machineIndex, newConfig);
  };

  return (
    <div className="main-container">
      {columns === 2 && (
        <div className="select-container" style={{ marginTop: "10px" }}>
          {columnsPerRow.map((numberOfColumns, rowIndex) => (
            <div
              key={`select-row-${rowIndex}-${numberOfColumns}`}
              className="select-row"
              style={{
                height: "calc(100%/" + (defaultColumns.length + 0.48) + ")",
                paddingBottom: "17px",
              }}
            >
              {rowIndex < 19 && (
                <input
                  type="checkbox"
                  checked={!mergedRowsLeft[rowIndex]}
                  onChange={(event) =>
                    handleCheckboxChange(rowIndex, "left", event)
                  }
                />
              )}
            </div>
          ))}
        </div>
      )}
      {columns !== 2 && (
        <div className="checkbox-container">
          {columnsPerRow.map((_, rowIndex) => (
            <div
              key={`checkbox-row-${rowIndex}`}
              className="checkbox-row"
              style={{
                height: "calc(100%/" + (defaultColumns.length + 0.48) + ")",
              }}
            >
              {rowIndex < 19 && rowIndex < mergedRows.length - 1 && (
                <input
                  type="checkbox"
                  checked={!mergedRows[rowIndex]}
                  onChange={(event) => handleRowCheckboxChange(rowIndex, event)}
                  disabled={rowIndex === mergedRows.length - 1}
                />
              )}
            </div>
          ))}
        </div>
      )}
      <div
        className={
          columns === 2 ? "small-table-container" : "table-container"
        }
      >
        {columns !== 2 && (
          <div className="header-row">
            <div className="header-cell invisible-cell "></div>
            {Array.from({ length: columns }).map((_, index) => (
              <div
                key={`header-cell-${index}`}
                className="header-cell"
                style={{ width: "calc(100% / " + columns + ")" }}
              >
                {index + 1}
              </div>
            ))}
          </div>
        )}
        {columnsPerRow.map((numberOfColumns, rowIndex) => {
          if (columns === 2) {
            return (
              <div
                key={`table-row-${rowIndex}-${numberOfColumns}`}
                className="table-row"
                style={{
                  height:
                    "calc(100%/" + (defaultColumns.length + 0.48) + ")",
                }}
              >
                <div className="index-cell">{rowIndex + 1}</div>
                <>
                  <div className="cell-group">
                    <div
                      className="cell"
                      style={{
                        borderBottom:
                          rowIndex < mergedRowsLeft.length &&
                          rowIndex >= 0 &&
                          mergedRowsLeft[rowIndex - 1]
                            ? "none"
                            : "1px solid black",
                      }}
                    ></div>
                  </div>
                  <div className="cell-group">
                    <div
                      className="cell"
                      style={{
                        borderBottom:
                          rowIndex < mergedRowsRight.length &&
                          rowIndex >= 0 &&
                          mergedRowsRight[rowIndex - 1]
                            ? "none"
                            : "1px solid black",
                      }}
                    ></div>
                  </div>
                </>
              </div>
            );
          } else {
            const numberOfColumnsInRow = getEffectiveColumnsPerRow(rowIndex);
            return (
              <div
                key={`table-row-${rowIndex}`}
                className="table-row"
                style={{
                  height:
                    "calc(100%/" + (defaultColumns.length + 0.48) + ")",
                }}
              >
                <div className="index-cell column-cell">{rowIndex + 1}</div>
                {Array.from({ length: numberOfColumnsInRow }).map(
                  (_, colIndex) => (
                    <div
                      key={`cell-${colIndex}`}
                      className="cell"
                      style={{
                        borderTop:
                          mergedRows[rowIndex - 1] && rowIndex > 0
                            ? "none"
                            : "1px solid black",
                        borderBottom:
                          mergedRows[rowIndex] &&
                          rowIndex !== mergedRows.length - 1
                            ? "none"
                            : "1px solid black",
                      }}
                    ></div>
                  )
                )}
              </div>
            );
          }
        })}
      </div>
      {columns === 2 ? (
        <div className="select-container" style={{ marginTop: "10px" }}>
          {columnsPerRow.map((numberOfColumns, rowIndex) => (
            <div
              key={`checkbox-select-row-${rowIndex}`}
              className="select-row"
              style={{
                height: "calc(100%/" + (defaultColumns.length + 0.48) + ")",
                paddingBottom: "17px",
              }}
            >
              {rowIndex < 19 && (
                <input
                  type="checkbox"
                  checked={!mergedRowsRight[rowIndex]}
                  onChange={(event) =>
                    handleCheckboxChange(rowIndex, "right", event)
                  }
                />
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="select-container">
          <div style={{ position: "absolute", top: "135px" }}>
            <p style={{ color: "gray", fontSize: "12px" }}>
              {t("modals.configurator.adjustNumberOfCells")}
            </p>
          </div>
          {columnsPerRow.map((_, rowIndex) => (
            <div
              key={`select-select-row-${rowIndex}`}
              className="select-row"
              style={{
                height:
                  "calc(100%/" + (defaultColumns.length + 0.48) + ")",
              }}
            >
              {rowIndex < 19 && (
                <select
                  value={getEffectiveColumnsPerRow(rowIndex)}
                  className="select"
                  onChange={(event) => handleSelectChange(rowIndex, event)}
                  disabled={mergedRows[rowIndex]}
                >
                  {options.map((option, index) => (
                    <option key={`option-${index}`} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DrumConfiguratorPage;
