import React, { useState, useContext, useEffect } from "react";
import { FormContext } from "../../context/FormContext";
import { DrumConfigurationContext } from "../../context/DrumConfigurationContext";
import "./MachinesPage.css";
import { useTranslation } from "react-i18next";
import Buttons from "../../components/Buttons/Buttons";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { ReactComponent as DoorRight } from "../../assets/icons/doorRight.svg";
import { ReactComponent as DoorLeft } from "../../assets/icons/doorLeft.svg";
import { ReactComponent as CaseLeft } from "../../assets/icons/caseLeft.svg";
import { ReactComponent as CaseRight } from "../../assets/icons/caseRight.svg";
import { ReactComponent as CaseTop } from "../../assets/icons/caseTop.svg";
import { ReactComponent as CaseBack } from "../../assets/icons/caseBack.svg";
import RadioButtonsGroup from "../../components/RadioButtons/RadioButtonsGroup";
import RadioImagesGroup from "../../components/RadioButtons/RadioImagesGroup";
import DrumConfigurationModal from "./ConfiguratorModal/ConfiguratorModal";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/remove.svg";
import CheckboxesGroup from "../../components/Checkbox/CheckboxesGroup";

const printDimensions = {
  D1080: {
    rightDoor: { width: 260, height: 1823 },
    leftDoor: { width: 824, height: 1823 },
    rightCase: { width: 1059, height: 1837 },
    leftCase: { width: 1059, height: 1837 },
    backCase: { width: 1088, height: 1837 },
    topCase: { width: 1059, height: 1088 },
  },
  "D1080 Neo": {
    rightDoor: { width: 260, height: 1823 },
    leftDoor: { width: 824, height: 1823 },
    rightCase: { width: 1059, height: 1837 },
    leftCase: { width: 1059, height: 1837 },
    backCase: { width: 1088, height: 1837 },
    topCase: { width: 1059, height: 1088 },
  },
  D810: {
    rightDoor: { width: 260, height: 1823 },
    leftDoor: { width: 524, height: 1823 },
    rightCase: { width: 758, height: 1837 },
    leftCase: { width: 758, height: 1837 },
    backCase: { width: 785, height: 1837 },
    topCase: { width: 758, height: 785 },
  },
  "D810 Neo": {
    rightDoor: { width: 260, height: 1823 },
    leftDoor: { width: 524, height: 1823 },
    rightCase: { width: 758, height: 1837 },
    leftCase: { width: 758, height: 1837 },
    backCase: { width: 785, height: 1837 },
    topCase: { width: 758, height: 785 },
  },
  "D540 Neo": {
    rightDoor: { width: 260, height: 1823 },
    leftDoor: { width: 524, height: 1823 },
    rightCase: { width: 758, height: 1837 },
    leftCase: { width: 758, height: 1837 },
    backCase: { width: 785, height: 1837 },
    topCase: { width: 758, height: 785 },
  },
  L40: {
    rightDoor: { width: 424, height: 1792 },
    leftDoor: { width: 424, height: 1792 },
    rightCase: { width: 601, height: 1792 },
    leftCase: { width: 601, height: 1792 },
    backCase: { width: 1018, height: 1792 },
    topCase: { width: 601, height: 1018 },
  },
  "L40 ME": {
    rightDoor: { width: 424, height: 1792 },
    leftDoor: { width: 424, height: 1792 },
    rightCase: { width: 601, height: 1792 },
    leftCase: { width: 601, height: 1792 },
    backCase: { width: 1018, height: 1792 },
    topCase: { width: 601, height: 1018 },
  },
  "L40 DE": {
    rightDoor: { width: 424, height: 1792 },
    leftDoor: { width: 424, height: 1792 },
    rightCase: { width: 601, height: 1792 },
    leftCase: { width: 601, height: 1792 },
    backCase: { width: 1018, height: 1792 },
    topCase: { width: 601, height: 1018 },
  },
  C240: {
    rightDoor: { width: 424, height: 1792 },
    leftDoor: { width: 424, height: 1792 },
    rightCase: { width: 601, height: 1792 },
    leftCase: { width: 601, height: 1792 },
    backCase: { width: 1018, height: 1792 },
    topCase: { width: 601, height: 1018 },
  },
  "T15 Neo": {
    rightDoor: { width: 424, height: 1792 },
    leftDoor: { width: 424, height: 1792 },
    rightCase: { width: 601, height: 1792 },
    leftCase: { width: 601, height: 1792 },
    backCase: { width: 1018, height: 1792 },
    topCase: { width: 601, height: 1018 },
  },
  T10: {
    rightDoor: { width: 424, height: 1792 },
    leftDoor: { width: 424, height: 1792 },
    rightCase: { width: 601, height: 1792 },
    leftCase: { width: 601, height: 1792 },
    backCase: { width: 1018, height: 1792 },
    topCase: { width: 601, height: 1018 },
  },
};

const defaultFormState = {
  machineType: "",
  version: "",
  barcodeReaderOption: "",
  barcodeReaderType: "",
  drumScanner: "",
  logoOnDisplay: "",
  color: "",
  rightDoorColor: "",
  leftDoorColor: "",
  rightCaseColor: "",
  leftCaseColor: "",
  topCaseColor: "",
  backCaseColor: "",
  rightDoorColorCheck: false,
  leftDoorColorCheck: false,
  leftCaseProjectCheck: false,
  rightCaseProjectCheck: false,
  backCaseProjectCheck: false,
  topCaseProjectCheck: false,
  isRightDoorSticker: false,
  isRightDoorRal: false,
  isLeftDoorSticker: false,
  isLeftDoorRal: false,
  isRightCaseSticker: false,
  isRightCaseRal: false,
  isLeftCaseSticker: false,
  isLeftCaseRal: false,
  isBackCaseSticker: false,
  isBackCaseRal: false,
  isTopCaseSticker: false,
  isTopCaseRal: false,
  rightDoorDetail: "standard",
  leftDoorDetail: "standard",
  rightCaseDetail: "standard",
  leftCaseDetail: "standard",
  backCaseDetail: "standard",
  topCaseDetail: "standard",
  panelSide: "",
  plexiglassWindows: "",
  l40ME: false,
  l40DE: false,
  l40PL: false,
  drumConfiguration: {
    columnsPerRow: [],
    mergedRows: [],
    mergedRowsLeft: [],
    mergedRowsRight: [],
    options: [],
    rows: 0,
    columns: 0,
  },
};

function MachinesPage() {
  const [formState, setFormState] = useState(defaultFormState);
  const { t } = useTranslation();
  const { formData, updateFormData } = useContext(FormContext);
  const { setDrumConfiguration } = useContext(DrumConfigurationContext);

  // Errors state is now an array, each index corresponds to a machine
  const [errors, setErrors] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedMachineIndex, setSelectedMachineIndex] = useState(0);

  useEffect(() => {
    if (!formData.machinesForms || formData.machinesForms.length === 0) {
      updateFormData({
        ...formData,
        machinesForms: [defaultFormState],
      });
    }
  }, []);

  const isFieldValid = (fieldName) => {
    const machineErrors = errors[selectedMachineIndex] || {};
    return !machineErrors[fieldName];
  };

  // Validate a single machine's form data
  const validateForm = (machineForm, index) => {
    const newErrors = {};
    const requiredFields = ["machineType", "color"];

    // Add version validation except for C240
    if (machineForm.machineType !== "C240") {
      requiredFields.push("version");
    }

    if (machineForm.version !== "OEM" && machineForm.machineType !== "C240" && machineForm.machineType !== "L40") {
      if (!machineForm.machineType.includes("Neo")) {
        // Maszyny, które nie są Neo
        requiredFields.push("barcodeReaderOption");

        if (machineForm.barcodeReaderOption === "yes") {
          requiredFields.push("barcodeReaderType");
        }
      } else {
        // Maszyny Neo
        requiredFields.push("barcodeReaderType");
      }
    }

    if (["L40", "L40 ME", "L40 DE"].includes(machineForm.machineType)) {
      requiredFields.push("panelSide");
    }

    // Add logoOnDisplay validation only for non-OEM versions
    if (
      machineForm.version !== "OEM" &&
      machineForm.machineType !== "C240" &&
      machineForm.machineType !== "L40" &&
      machineForm.machineType !== ""
    ) {
      requiredFields.push("logoOnDisplay");
    }

    if (
      machineForm.machineType === "D810" &&
      machineForm.version === "PRO" &&
      !machineForm.machineType.includes("Neo")
    ) {
      requiredFields.push("drumScanner");
    }

    requiredFields.forEach((field) => {
      const fieldValue = machineForm[field];
      if (
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue === ""
      ) {
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors((prevErrors) => {
      const newErrorsArray = [...prevErrors];
      newErrorsArray[index] = newErrors;
      return newErrorsArray;
    });

    return Object.values(newErrors).every((v) => !v);
  };

  const switchInputError = (fieldName, value) => {
    setErrors((prevErrors) => {
      const newErrorsArray = [...prevErrors];
      const machineErrors = newErrorsArray[selectedMachineIndex] || {};
      machineErrors[fieldName] = value;
      newErrorsArray[selectedMachineIndex] = machineErrors;
      return newErrorsArray;
    });
  };

  useEffect(() => {
    if (
      formData &&
      formData.machinesForms &&
      formData.machinesForms[selectedMachineIndex]
    ) {
      const machinesForm = formData.machinesForms[selectedMachineIndex];
      setFormState({
        ...defaultFormState,
        ...machinesForm,
      });
    } else {
      setFormState(defaultFormState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, selectedMachineIndex]);

  const updateFormState = (name, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, id, checked } = e.target;
    let newValue = value;

    if (type === "radio") {
      switchInputError(id || name, false);
    }

    if (value === "true" || value === "false") {
      newValue = value === "true";
    }

    if (type === "checkbox") {
      newValue = checked;

      if (name === "l40PL" && checked) {
        setFormState(prevState => ({
          ...prevState,
          [name]: checked,
          plexiglassWindows: "yes"
        }));
        return;
      } else if (name === "l40PL" && !checked) {
        setFormState(prevState => ({
          ...prevState,
          [name]: checked,
          plexiglassWindows: "no"
        }));
        return;
      }
    }

    setFormState({
      ...formState,
      [name]: newValue,
    });
  };

  const fullConfiguration = () => {
    const event = { target: { value: formState.machineType } };
    handleMachineChange(event);
  };

  const handleMachineChange = (event) => {
    const value = event.target.value;

    switchInputError("machineType", false);
    const newFormState = {
      ...defaultFormState,
      machineType: value,
    };

    if (value === "T10" || value === "T15 Neo") {
      newFormState.version = "PRO";
    }
    if (value.includes("Neo")) {
      newFormState.drumScanner = "default";
    }
    if (value === "L40" || value.includes("L40")) {
      newFormState.plexiglassWindows = "no";
    }

    setFormState(newFormState);

    let newColumns = 0;
    let newRows = 0;
    let newOptions = [];

    switch (value) {
      case "D810":
      case "D810 Neo":
        newColumns = 45;
        newRows = 18;
        newOptions = [45, 30, 15];
        break;
      case "D1080":
      case "D1080 Neo":
        newColumns = 60;
        newRows = 18;
        newOptions = [60, 40, 20];
        break;
      case "D540 Neo":
        newColumns = 30;
        newRows = 18;
        newOptions = [30, 20, 10];
        break;
      case "L40":
      case "L40 ME":
      case "L40 DE":
        newColumns = 2;
        newRows = 20;
        newOptions = [2];
        break;
      case "C240":
        newColumns = 12;
        newRows = 20;
        newOptions = [12, 8, 4];
        break;
      case "T15 Neo":
      case "T10":
        newColumns = 15;
        newRows = 1;
        newOptions = [15];
        break;
      default:
        break;
    }

    const newDrumConfiguration = {
      columnsPerRow: Array(newRows).fill(newColumns),
      mergedRows: Array(newRows).fill(false),
      mergedRowsLeft: Array(newRows).fill(false),
      mergedRowsRight: Array(newRows).fill(false),
      rows: newRows,
      columns: newColumns,
      options: newOptions,
    };

    setDrumConfiguration(selectedMachineIndex, newDrumConfiguration);
  };

  const handleOpenPopup = () => {
    if (formState.machineType) {
      setIsPopupOpen(true);
    } else {
      alert("Nie wybrano maszyny.");
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleButtonClick = () => {
    const machinesForms = [...(formData.machinesForms || [])];
    machinesForms[selectedMachineIndex] = { ...formState };
    updateFormData({ machinesForms });
  };

  const useDoorCaseEffect = (
    colorCheck,
    color,
    isSticker,
    detailName,
    fallbackDetail = "page.machines.radio.standard"
  ) => {
    useEffect(() => {
      let newDetail;
      if (!colorCheck && !color && !isSticker) {
        newDetail = t(fallbackDetail);
      } else if (colorCheck && color && !isSticker) {
        newDetail = `RAL ${color}`;
      } else if (colorCheck && color && isSticker) {
        newDetail = `RAL ${color} + ${t("page.machines.checkbox.sticker")}`;
      } else if (colorCheck && !color && isSticker) {
        newDetail = `${t("page.machines.checkbox.sticker")}`;
      } else {
        newDetail = t(fallbackDetail);
      }
      updateFormState(detailName, newDetail);
    }, [colorCheck, color, isSticker, detailName, fallbackDetail]);
  };

  useDoorCaseEffect(
    formState.rightDoorColorCheck,
    formState.rightDoorColor,
    formState.isRightDoorSticker,
    "rightDoorDetail"
  );

  useDoorCaseEffect(
    formState.leftDoorColorCheck,
    formState.leftDoorColor,
    formState.isLeftDoorSticker,
    "leftDoorDetail"
  );

  useDoorCaseEffect(
    formState.rightCaseProjectCheck,
    formState.rightCaseColor,
    formState.isRightCaseSticker,
    "rightCaseDetail"
  );

  useDoorCaseEffect(
    formState.leftCaseProjectCheck,
    formState.leftCaseColor,
    formState.isLeftCaseSticker,
    "leftCaseDetail"
  );

  useDoorCaseEffect(
    formState.topCaseProjectCheck,
    formState.topCaseColor,
    formState.isTopCaseSticker,
    "topCaseDetail"
  );

  useDoorCaseEffect(
    formState.backCaseProjectCheck,
    formState.backCaseColor,
    formState.isBackCaseSticker,
    "backCaseDetail"
  );

  const handlePaintingChange = (
    e,
    fieldName,
    fieldType,
    detailSetter,
    color,
    sticker,
    colorSetter = null
  ) => {
    if (fieldType === "text") {
      updateFormState(fieldName, e.target.value);
    } else {
      setFormState((prevState) => {
        let newDetail;
        const newCheckState = !prevState[fieldName];

        if (colorSetter && !newCheckState) {
          updateFormState(colorSetter, "");
        }

        if (!newCheckState && !color && !sticker) {
          newDetail = t("page.machines.radio.standard");
        } else if (color && !sticker) {
          newDetail = `RAL: ${color}`;
        } else if (color && sticker) {
          newDetail = `RAL: ${color} + ${t("page.machines.checkbox.sticker")}`;
        } else if (!color && sticker) {
          newDetail = `${t("page.machines.checkbox.sticker")}`;
        } else {
          newDetail = t("page.machines.radio.standard");
        }

        updateFormState(detailSetter, newDetail);

        return {
          ...prevState,
          [fieldName]: newCheckState,
        };
      });
    }
  };

  const getVersionOptions = () => {
    const { machineType } = formState;
    switch (machineType) {
      case "T10":
      case "T15 Neo":
        return [{ value: "PRO", label: t("page.machines.radio.pro") }];
      case "C240":
        return [];
      default:
        return [
          { value: "BASIC", label: t("page.machines.radio.basic") },
          { value: "PRO", label: t("page.machines.radio.pro") },
          {
            value: "OEM",
            label: (
              <>
                {t("page.machines.radio.oem")}{" "}
                <span style={{ color: "#666666", fontSize: "14px" }}>
                  / {t("page.machines.label.withoutPanel")}
                </span>
              </>
            ),
          },
        ];
    }
  };

  const versionOptions = getVersionOptions();

  const logoOnDisplayOptions = [
    {
      value: "standard",
      label: t("page.machines.radio.standard"),
    },
    {
      value: "custom",
      label: t("page.machines.radio.custom"),
    },
  ];

  const colorOptions = [
    {
      value: "standardcolor",
      label: (
        <span style={{ marginRight: "62px" }}>
          {t("page.machines.radio.standardColor")}
        </span>
      ),
    },
    {
      value: "nonstandardcolor",
      label: t("page.machines.radio.nonstandardColor"),
    },
  ];

  const handleAddMachine = () => {
    const machinesForms = [...(formData.machinesForms || [])];
    machinesForms[selectedMachineIndex] = { ...formState };

    let allValid = true;
    machinesForms.forEach((machineForm, index) => {
      const isValid = validateForm(machineForm, index);
      if (!isValid) {
        allValid = false;
      }
    });

    if (!allValid) {
      return;
    }

    // All machines are valid, proceed to add a new one
    machinesForms.push(defaultFormState);
    updateFormData({ machinesForms });
    setSelectedMachineIndex(machinesForms.length - 1);

    // Reset errors for the new machine
    setErrors((prevErrors) => [...prevErrors, {}]);
  };

  const handleBadgeClick = (index) => {
    const machinesForms = [...(formData.machinesForms || [])];
    machinesForms[selectedMachineIndex] = { ...formState };
    updateFormData({ machinesForms });

    setSelectedMachineIndex(index);
  };

  const shouldShowConfigurator = (machineType) => {
    return machineType !== "T10" &&
           machineType !== "T15 Neo" &&
           machineType !== "L40" &&
           !machineType.includes("L40");
  };

  return (
    <div className="machines-page">
      <div className="machines-badges">
        {formData.machinesForms &&
          formData.machinesForms.map((machine, index) => (
            <div
              key={index}
              className={`machine-badge ${
                index === selectedMachineIndex ? "active" : ""
              }`}
            >
              <span onClick={() => handleBadgeClick(index)}>
                {`${t("page.machines.label.machine")} ${index + 1}`}
              </span>
              {index === selectedMachineIndex &&
                formData.machinesForms.length > 1 && (
                  <button
                    className="remove-machine-button"
                    onClick={() => {
                      const newMachineForms = formData.machinesForms.filter(
                        (_, i) => i !== index
                      );
                      updateFormData({ machinesForms: newMachineForms });
                      if (selectedMachineIndex >= index) {
                        setSelectedMachineIndex(Math.max(0, selectedMachineIndex - 1));
                      }
                      // Remove corresponding errors
                      setErrors((prevErrors) =>
                        prevErrors.filter((_, i) => i !== index)
                      );
                    }}
                  >
                    <RemoveIcon />
                  </button>
                )}
            </div>
          ))}
        <div className="add-machine-button" onClick={handleAddMachine}>
          <PlusIcon />
        </div>
      </div>

      <section className="machine-type">
        <h2>{t("page.machines.header.machineType")}</h2>
        <div className="radio-group">
          <RadioImagesGroup
            id="machineType"
            machineType={formState.machineType}
            handleChange={(e) => handleMachineChange(e)}
            isValid={isFieldValid("machineType")}
          />
          {formState.machineType && versionOptions.length > 0 && (
            <RadioButtonsGroup
              id="version"
              label={t("page.machines.label.version")}
              options={versionOptions}
              value={formState.version}
              handleChange={handleInputChange}
              isValid={isFieldValid("version")}
              groupSize={3}
            />
          )}
          {formState.machineType === "L40" && (
            <CheckboxesGroup
            id="additionalOptions"
            label={t("page.machines.label.additionalOptions")}
            options={[
              {
                name: "l40ME",
                label: "L40 ME",
                description: t("page.machines.description.l40ME"),
              },
              {
                name: "l40DE",
                label: "L40 DE",
                description: t("page.machines.description.l40DE"),
              },
              {
                name: "l40PL",
                label: "L40 PL",
                description: t("page.machines.description.l40PL"),
              },
            ]}
            values={formState}
            handleChange={handleInputChange}
          />
          )}

          {/* Configurator Section */}
          {formState.machineType === "L40" && (
            <section className="shelf-configuration">
              <h2>{t("page.machines.header.shelfConfiguration")}</h2>
              <div className="drum-configuration-link" onClick={handleOpenPopup}>
                <span>{t("page.machines.btn.configureShelf")}</span>
                <LinkIcon />
              </div>
            </section>
          )}

          {/* Touch Panel Section */}
          {formState.machineType === "L40" && (
            <section className="touch-panel">
              <h2>{t("page.machines.header.touchPanel")}</h2>
              <RadioButtonsGroup
                id="panelSide"
                label={t("page.machines.label.panelSide")}
                options={[
                  { value: "left", label: t("page.machines.radio.leftSide") },
                  { value: "right", label: t("page.machines.radio.rightSide") },
                ]}
                value={formState.panelSide}
                handleChange={handleInputChange}
                isValid={isFieldValid("panelSide")}
              />
            </section>
          )}
          {formState.machineType &&
            ((['L40', 'L40 ME', 'L40 DE'].includes(formState.machineType)) ||
             (formState.version !== "OEM" &&
              !formState.machineType.includes("Neo") &&
              formState.machineType !== "C240")) &&
            (
            <RadioButtonsGroup
              id="barcodeReaderOption"
              label={t("page.machines.label.barcodeReader")}
              options={[
                { value: "yes", label: t("global.label.yes") },
                { value: "no", label: t("global.label.no") },
              ]}
              value={formState.barcodeReaderOption}
              handleChange={handleInputChange}
              isValid={isFieldValid("barcodeReaderOption")}
            />
          )}
          {formState.machineType &&
            ((formState.version !== "OEM" &&
              !formState.machineType.includes("Neo") &&
              formState.machineType !== "C240" &&
              formState.barcodeReaderOption === "yes") ||
             (formState.version !== "OEM" &&
              formState.machineType.includes("Neo") &&
              formState.machineType !== "C240") ||
             (['L40', 'L40 ME', 'L40 DE'].includes(formState.machineType) &&
              formState.barcodeReaderOption === "yes")) &&
            (
            <RadioButtonsGroup
              id="barcodeReaderType"
              label={t("page.machines.label.barcodeReaderType")}
              options={[
                {
                  value: "standard",
                  label: t("page.machines.radio.standardReader"),
                },
                {
                  value: "custom",
                  label: t("page.machines.radio.customReader"),
                },
              ]}
              value={formState.barcodeReaderType}
              handleChange={handleInputChange}
              isValid={isFieldValid("barcodeReaderType")}
            />
          )}
          {formState.version === "PRO" &&
            formState.machineType === "D810" &&
            !formState.machineType.includes("Neo") && (
              <RadioButtonsGroup
                id="drumScanner"
                label={t("page.machines.label.drumScanner")}
                value={formState.drumScanner}
                handleChange={handleInputChange}
                isValid={isFieldValid("drumScanner")}
              />
            )}
          {formState.machineType === "C240" && (
            <>
              <div className="primary-info">
                <InfoIcon style={{ width: "20px", height: "20px" }} />
                {t("page.machines.tooltip.internetConnection")}
              </div>
            </>
            )}
        </div>
      </section>

      {shouldShowConfigurator(formState.machineType) &&
        formState.machineType !== "C240" &&
        formState.machineType !== "" && (
          <section className="configuration">
            <h2 style={{ marginTop: "4rem" }}>
              {formState.machineType === "L40"
                ? t("page.machines.header.shelfConfiguration")
                : t("page.machines.header.configuration")}
            </h2>
            <div className="drum-configuration-link" onClick={handleOpenPopup}>
              <span>
                {formState.machineType === "L40"
                  ? t("page.machines.btn.configureShelf")
                  : t("page.machines.btn.configureDrum")}
              </span>
              <LinkIcon />
            </div>
          </section>
        )}

      {formState.machineType &&
        formState.machineType !== "" && (
          <section className="colors">
            <h2>{t("page.machines.header.colors")}</h2>
            <div className="radio-group">
              {formState.version !== "OEM" && formState.machineType !== "L40" && formState.machineType !== "C240" && (
                <RadioButtonsGroup
                  id="logoOnDisplay"
                  label={t("page.machines.label.logoOnDisplay")}
                  options={logoOnDisplayOptions}
                  value={formState.logoOnDisplay}
                  handleChange={handleInputChange}
                  isValid={isFieldValid("logoOnDisplay")}
                />
              )}
              <div className="input-box">
                <RadioButtonsGroup
                  id="color"
                  label={t("page.machines.label.color")}
                  options={colorOptions}
                  value={formState.color}
                  handleChange={handleInputChange}
                  isValid={isFieldValid("color")}
                  tooltip={true}
                />
                <div style={{ display: "grid" }}>
                  <span
                    style={{
                      width: "233px",
                      marginLeft: "0px",
                    }}
                    className="side-text"
                  >
                    {t("page.machines.tooltip.doorColor")}
                  </span>
                  <span></span>
                </div>
              </div>
            </div>
            {formState.color === "nonstandardcolor" &&
              formState.machineType !== "" &&
              !["C240", "T10", "T15 Neo"].includes(formState.machineType) && (
            <React.Fragment>
						<div className="input-box">
							<div
								className="checkbox-input-box"
								onClick={(e) =>
									handlePaintingChange(
										e,
										"rightDoorColorCheck",
										"checkbox",
										"rightDoorDetail",
										formState.rightDoorColor,
										formState.isRightDoorSticker
									)
								}
							>
								<input
									type="checkbox"
									name="rightDoorColorCheck"
									checked={formState.rightDoorColorCheck}
									readOnly
								/>
								<label>{t("page.machines.checkbox.rightDoorColor")}</label>
								<DoorRight />
							</div>
							{formState.rightDoorColorCheck && (
								<div className="checkbox-input-box">
									<input
										type="checkbox"
										name="isRightDoorRAL"
										checked={formState.isRightDoorRal}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isRightDoorRal",
												"checkbox",
												"rightDoorDetail",
												formState.rightDoorColor,
												formState.isRightDoorSticker,
												"rightDoorColor"
											)
										}
									/>
									<input
										maxLength={4}
										className="input-ral"
										placeholder={t("page.machines.label.ralCode")}
										type="text"
										disabled={!formState.isRightDoorRal}
										name="rightDoorColor"
										value={formState.rightDoorColor}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"rightDoorColor",
												"text",
												"rightDoorDetail",
												formState.rightDoorColor,
												formState.isRightDoorSticker
											)
										}
									/>
									<input
										type="checkbox"
										name="isRightDoorSticker"
										checked={formState.isRightDoorSticker}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isRightDoorSticker",
												"checkbox",
												"rightDoorDetail",
												formState.rightDoorColor,
												formState.isRightDoorSticker
											)
										}
									/>
									<label
										onClick={(e) =>
											updateFormState(
												"isRightDoorSticker",
												!formState.isRightDoorSticker
											)
										}
									>
										{t("page.machines.checkbox.sticker")}
									</label>
									{formState.machineType !== "F80" && (
										<div className="overprint-info">
											<p>
												/{" "}
												{`${t("page.machines.label.printDimensions")} ${t(
													"page.machines.label.width"
												)} ${printDimensions[formState.machineType]["rightDoor"]
													.width
													} ${t("page.machines.label.height")} ${printDimensions[formState.machineType]["rightDoor"]
														.height
													}`}{" "}
												mm
											</p>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="input-box">
							<div
								className="checkbox-input-box"
								onClick={(e) =>
									handlePaintingChange(
										e,
										"leftDoorColorCheck",
										"checkbox",
										"leftDoorDetail",
										formState.leftDoorColor,
										formState.isLeftDoorSticker
									)
								}
							>
								<input
									type="checkbox"
									name="leftDoorColorCheck"
									checked={formState.leftDoorColorCheck}
									readOnly
								/>
								<label>{t("page.machines.checkbox.leftDoorColor")}</label>
								<DoorLeft />
							</div>
							{formState.leftDoorColorCheck && (
								<div className="checkbox-input-box">
									<input
										type="checkbox"
										name="isLeftDoorRal"
										checked={formState.isLeftDoorRal}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isLeftDoorRal",
												"checkbox",
												"leftDoorDetail",
												formState.leftDoorColor,
												formState.isLeftDoorSticker,
												"leftDoorColor"
											)
										}
									/>
									<input
										maxLength={4}
										className="input-ral"
										placeholder={t("page.machines.label.ralCode")}
										type="text"
										disabled={!formState.isLeftDoorRal}
										name="leftDoorColor"
										value={formState.leftDoorColor}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"leftDoorColor",
												"text",
												"leftDoorDetail",
												formState.leftDoorColor,
												formState.isLeftDoorSticker
											)
										}
									/>
									<input
										type="checkbox"
										name="isLeftDoorSticker"
										checked={formState.isLeftDoorSticker}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isLeftDoorSticker",
												"checkbox",
												"leftDoorDetail",
												formState.leftDoorColor,
												formState.isLeftDoorSticker
											)
										}
									/>
									<label
										onClick={(e) =>
											updateFormState(
												"isLeftDoorSticker",
												!formState.isLeftDoorSticker
											)
										}
									>
										{t("page.machines.checkbox.sticker")}
									</label>
									{formState.machineType !== "F80" && (
										<div className="overprint-info">
											<p>
												/{" "}
												{`${t("page.machines.label.printDimensions")} ${t(
													"page.machines.label.width"
												)} ${printDimensions[formState.machineType]["leftDoor"]
													.width
													} ${t("page.machines.label.height")} ${printDimensions[formState.machineType]["leftDoor"]
														.height
													}`}{" "}
												mm
											</p>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="input-box">
							<div
								className="checkbox-input-box"
								onClick={(e) =>
									handlePaintingChange(
										e,
										"rightCaseProjectCheck",
										"checkbox",
										"rightCaseDetail",
										formState.rightCaseColor,
										formState.isRightCaseSticker
									)
								}
							>
								<input
									type="checkbox"
									name="rightCaseProjectCheck"
									checked={formState.rightCaseProjectCheck}
									readOnly
								/>
								<label>{t("page.machines.checkbox.rightCaseDesign")}</label>
								<CaseRight />
							</div>
							{formState.rightCaseProjectCheck && (
								<div className="checkbox-input-box">
									<input
										type="checkbox"
										name="isRightCaseRal"
										checked={formState.isRightCaseRal}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isRightCaseRal",
												"checkbox",
												"rightCaseDetail",
												formState.rightCaseColor,
												formState.isRightCaseSticker,
												"rightCaseColor"
											)
										}
									/>
									<input
										maxLength={4}
										className="input-ral"
										placeholder={t("page.machines.label.ralCode")}
										type="text"
										disabled={!formState.isRightCaseRal}
										name="rightCaseColor"
										value={formState.rightCaseColor}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"rightCaseColor",
												"text",
												"rightCaseDetail",
												formState.rightCaseColor,
												formState.isRightCaseSticker
											)
										}
									/>
									<input
										type="checkbox"
										name="isRightCaseSticker"
										checked={formState.isRightCaseSticker}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isRightCaseSticker",
												"checkbox",
												"rightCaseDetail",
												formState.rightCaseColor,
												formState.isRightCaseSticker
											)
										}
									/>
									<label
										onClick={(e) =>
											updateFormState(
												"isRightCaseSticker",
												!formState.isRightCaseSticker
											)
										}
									>
										{t("page.machines.checkbox.sticker")}
									</label>
									{formState.machineType !== "F80" && (
										<div className="overprint-info">
											<p>
												/{" "}
												{`${t("page.machines.label.printDimensions")} ${t(
													"page.machines.label.width"
												)} ${printDimensions[formState.machineType]["rightCase"]
													.width
													} ${t("page.machines.label.height")} ${printDimensions[formState.machineType]["rightCase"]
														.height
													}`}{" "}
												mm
											</p>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="input-box">
							<div
								className="checkbox-input-box"
								onClick={(e) =>
									handlePaintingChange(
										e,
										"leftCaseProjectCheck",
										"checkbox",
										"leftCaseDetail",
										formState.leftCaseColor,
										formState.isLeftCaseSticker
									)
								}
							>
								<input
									type="checkbox"
									name="leftCaseProjectCheck"
									checked={formState.leftCaseProjectCheck}
									readOnly
								/>
								<label>{t("page.machines.checkbox.leftCaseDesign")}</label>
								<CaseLeft />
							</div>
							{formState.leftCaseProjectCheck && (
								<div className="checkbox-input-box">
									<input
										type="checkbox"
										name="isLeftCaseRal"
										checked={formState.isLeftCaseRal}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isLeftCaseRal",
												"checkbox",
												"leftCaseDetail",
												formState.leftCaseColor,
												formState.isLeftCaseSticker,
												"leftCaseColor"
											)
										}
									/>
									<input
										maxLength={4}
										className="input-ral"
										placeholder={t("page.machines.label.ralCode")}
										type="text"
										disabled={!formState.isLeftCaseRal}
										name="leftCaseColor"
										value={formState.leftCaseColor}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"leftCaseColor",
												"text",
												"leftCaseDetail",
												formState.leftCaseColor,
												formState.isLeftCaseSticker
											)
										}
									/>
									<input
										type="checkbox"
										name="isLeftCaseSticker"
										checked={formState.isLeftCaseSticker}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isLeftCaseSticker",
												"checkbox",
												"leftCaseDetail",
												formState.leftCaseColor,
												formState.isLeftCaseSticker
											)
										}
									/>
									<label
										onClick={(e) =>
											updateFormState(
												"isLeftCaseSticker",
												!formState.isLeftCaseSticker
											)
										}
									>
										{t("page.machines.checkbox.sticker")}
									</label>
									{formState.machineType !== "F80" && (
										<div className="overprint-info">
											<p>
												/
												{`${t("page.machines.label.printDimensions")} ${t(
													"page.machines.label.width"
												)} ${printDimensions[formState.machineType]["leftCase"]
													.width
													} ${t("page.machines.label.height")} ${printDimensions[formState.machineType]["leftCase"]
														.height
													}`}{" "}
												mm
											</p>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="input-box">
							<div
								className="checkbox-input-box"
								onClick={(e) =>
									handlePaintingChange(
										e,
										"backCaseProjectCheck",
										"checkbox",
										"backCaseDetail",
										formState.backCaseColor,
										formState.isBackCaseSticker
									)
								}
							>
								<input
									type="checkbox"
									name="backCaseProjectCheck"
									checked={formState.backCaseProjectCheck}
									readOnly
								/>
								<label>{t("page.machines.checkbox.backCaseDesign")}</label>
								<CaseBack />
							</div>
							{formState.backCaseProjectCheck && (
								<div className="checkbox-input-box">
									<input
										type="checkbox"
										name="isBackCaseRal"
										checked={formState.isBackCaseRal}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isBackCaseRal",
												"checkbox",
												"backCaseDetail",
												formState.backCaseColor,
												formState.isBackCaseSticker,
												"backCaseColor"
											)
										}
									/>
									<input
										maxLength={4}
										className="input-ral"
										placeholder={t("page.machines.label.ralCode")}
										type="text"
										disabled={!formState.isBackCaseRal}
										name="backCaseColor"
										value={formState.backCaseColor}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"backCaseColor",
												"text",
												"backCaseDetail",
												formState.backCaseColor,
												formState.isBackCaseSticker
											)
										}
									/>
									<input
										type="checkbox"
										name="isBackCaseSticker"
										checked={formState.isBackCaseSticker}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isBackCaseSticker",
												"checkbox",
												"backCaseDetail",
												formState.backCaseColor,
												formState.isBackCaseSticker
											)
										}
									/>
									<label
										onClick={(e) =>
											updateFormState(
												"isBackCaseSticker",
												!formState.isBackCaseSticker
											)
										}
									>
										{t("page.machines.checkbox.sticker")}
									</label>
									{formState.machineType !== "F80" && (
										<div className="overprint-info">
											<p>
												/{" "}
												{`${t("page.machines.label.printDimensions")} ${t(
													"page.machines.label.width"
												)} ${printDimensions[formState.machineType]["backCase"]
													.width
													} ${t("page.machines.label.height")} ${printDimensions[formState.machineType]["backCase"]
														.height
													}`}{" "}
												mm
											</p>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="input-box">
							<div
								className="checkbox-input-box"
								onClick={(e) =>
									handlePaintingChange(
										e,
										"topCaseProjectCheck",
										"checkbox",
										"topCaseDetail",
										formState.topCaseColor,
										formState.isTopCaseSticker
									)
								}
							>
								<input
									type="checkbox"
									name="topCaseProjectCheck"
									checked={formState.topCaseProjectCheck}
									readOnly
								/>
								<label>{t("page.machines.checkbox.topCaseDesign")}</label>
								<CaseTop />
							</div>
							{formState.topCaseProjectCheck && (
								<div className="checkbox-input-box">
									<input
										type="checkbox"
										name="isTopCaseRal"
										checked={formState.isTopCaseRal}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isTopCaseRal",
												"checkbox",
												"topCaseDetail",
												formState.topCaseColor,
												formState.isTopCaseSticker,
												"topCaseColor"
											)
										}
									/>
									<input
										maxLength={4}
										className="input-ral"
										placeholder={t("page.machines.label.ralCode")}
										type="text"
										disabled={!formState.isTopCaseRal}
										name="topCaseColor"
										value={formState.topCaseColor}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"topCaseColor",
												"text",
												"topCaseDetail",
												formState.topCaseColor,
												formState.isTopCaseSticker
											)
										}
									/>
									<input
										type="checkbox"
										name="isTopCaseSticker"
										checked={formState.isTopCaseSticker}
										onChange={(e) =>
											handlePaintingChange(
												e,
												"isTopCaseSticker",
												"checkbox",
												"topCaseDetail",
												formState.topCaseColor,
												formState.isTopCaseSticker
											)
										}
									/>
									<label
										onClick={(e) =>
											updateFormState(
												"isTopCaseSticker",
												!formState.isTopCaseSticker
											)
										}
									>
										{t("page.machines.checkbox.sticker")}
									</label>
									{formState.machineType !== "F80" && (
										<div className="overprint-info">
											<p>
												/{" "}
												{`${t("page.machines.label.printDimensions")} ${t(
													"page.machines.label.width"
												)} ${printDimensions[formState.machineType]["topCase"]
													.width
													} ${t("page.machines.label.height")} ${printDimensions[formState.machineType]["topCase"]
														.height
													}`}{" "}
												mm
											</p>
										</div>
									)}
								</div>
							)}
						</div>

						<div className="primary-info">
							<InfoIcon style={{ width: "15px", height: "15px" }} />
							{t("page.machines.tooltip.stickerMail")}
						</div>
						<div className="primary-info">
							<InfoIcon style={{ width: "35px", height: "35px" }} />
							{t("page.machines.tooltip.cancelOrder")}
						</div>
					</React.Fragment>
				)}
				</section>
			 )}

			<Buttons
				validateForm={() => validateForm(formState, selectedMachineIndex)}
				handleButtonClick={handleButtonClick}
			></Buttons>

			<DrumConfigurationModal
				isPopupOpen={isPopupOpen}
				machineType={formState.machineType}
				fullConfiguration={fullConfiguration}
				handleClosePopup={handleClosePopup}
				machineIndex={selectedMachineIndex}
			/>
		</div>
	);
}

export default MachinesPage;
