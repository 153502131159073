// SummaryPage.js

import React, { useContext, useState, useEffect } from "react";
import { FormContext } from "../../context/FormContext";
import Buttons from "../../components/Buttons/Buttons";
import "./SummaryPage.css";
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkIcon } from "../../assets/icons/link.svg";
import { Typography } from "@mui/material";

const SummaryPage = () => {
  const [formState, setFormState] = useState({
    orderTermsAccepted: false,
    implementationTermsAccepted: false,
    deliveryTermsAccepted: false,
  });

  const { t } = useTranslation();
  const { formData, updateFormData } = useContext(FormContext);
  const [errors, setErrors] = useState({});

  const isFieldValid = (fieldName) => {
    return !errors[fieldName];
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    setFormState({
      ...formState,
      [name]: newValue,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formState.orderTermsAccepted) {
      newErrors.orderTermsAccepted = true;
    }

    if (!formState.implementationTermsAccepted) {
      newErrors.implementationTermsAccepted = true;
    }

    if (!formState.deliveryTermsAccepted) {
      newErrors.deliveryTermsAccepted = true;
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const switchInputError = (fieldName, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: value,
    }));
  };

  const handleCheckboxChange = (name) => (event) => {
    const newValue = !formState[name];

    setFormState({
      ...formState,
      [name]: newValue,
    });

    // Clear the error when checkbox is checked
    if (newValue) {
      setErrors(prevErrors => ({
        ...prevErrors,
        [name]: false,
      }));
    }
  };

  const handleButtonClick = () => {
    const summaryForm = {
      ...formState,
    };
    updateFormData({ summaryForm });
  };

  useEffect(() => {
    if (formData && formData.summaryForm) {
      const summaryForm = formData.summaryForm;
      setFormState({
        orderTermsAccepted: summaryForm.orderTermsAccepted || false,
        implementationTermsAccepted: summaryForm.implementationTermsAccepted || false,
        deliveryTermsAccepted: summaryForm.deliveryTermsAccepted || false,
      });
    }
  }, [formData]);

  return (
    <div className="summary-page">
      <h2>{t("page.summary.header.summary")}</h2>

      {/* Contact Form Summary */}
      <div className="section">
        <h3>{t("page.summary.header.contact")}</h3>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.name")}</span>
          <span className="summary-value">{formData.contactForm?.name}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.company")}</span>
          <span className="summary-value">{formData.contactForm?.company}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.companyAddress")}
          </span>
          <span className="summary-value">{formData.contactForm?.address}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.nip")}</span>
          <span className="summary-value">{formData.contactForm?.nip}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.phone")}</span>
          <span className="summary-value">{formData.contactForm?.phone}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.email")}</span>
          <span className="summary-value">{formData.contactForm?.email}</span>
        </div>
      </div>

      {/* Billing Form Summary */}
      <div className="section">
        <h3>{t("page.summary.header.billing")}</h3>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.billingCompany")}
          </span>
          <span className="summary-value">{formData.billingForm?.billingCompany}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.billingAddress")}
          </span>
          <span className="summary-value">{formData.billingForm?.billingAddress}</span>
        </div>
        <div className="summary-row">
          <span className="summary-label">{t("page.summary.label.billingNip")}</span>
          <span className="summary-value">{formData.billingForm?.billingNip}</span>
        </div>
      </div>

      {/* Installation Form Summary */}
      <div className="section">
        <h3>{t("page.summary.header.installation")}</h3>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.installationCountry")}
          </span>
          <span className="summary-value">
            {formData.installationForm?.country
              ? t(`countries.${formData.installationForm?.country}`)
              : ""}
          </span>
        </div>
        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.installationLocation")}
          </span>
          <span className="summary-value">{formData.installationForm?.address}</span>
        </div>

        <div className="summary-row">
          <span className="summary-label">
            {t("page.summary.label.customerCompany")}
          </span>
          <span className="summary-value">{formData.installationForm?.company}</span>
        </div>
      </div>

      {/* Machines Details Summary */}
      <div className="section">
        <h3>{t("page.summary.header.machine")}</h3>
        {formData.machinesForms &&
          formData.machinesForms.map((machine, index) => (
            <div key={index} className="machine-summary">
              <h4>{`${index + 1}`}</h4>
              <div className="summary-row">
                <span className="summary-label">
                  {t("page.summary.label.machineModel")}
                </span>
                <span className="summary-value font-bold-700">
                  {machine.machineType} {machine.version}
                </span>
              </div>
              <div className="summary-row">
                <span className="summary-label">
                  {t("page.machines.label.barcodeReader")}:
                </span>
                <span className="summary-value">
                  {machine.barcodeReader === "standard"
                    ? t("page.machines.radio.standardReader")
                    : t("page.machines.radio.customReader")}
                </span>
              </div>
              {machine.drumScanner && (
                <div className="summary-row">
                  <span className="summary-label">
                    {t("page.machines.label.drumScanner")}
                  </span>
                  <span className="summary-value">{machine.drumScanner}</span>
                </div>
              )}
              <div className="summary-row">
                <span className="summary-label">
                  {t("page.summary.label.machineColor")}
                </span>
                <span className="summary-value">
                  {machine.color === "standardcolor"
                    ? t("page.machines.radio.standardColor")
                    : t("page.machines.radio.nonstandardColor")}
                </span>
              </div>
            </div>
          ))}
      </div>

      <section className="rules">
        <a
          href="https://asdsystems.eu/regulamin/"
          className="contract-link"
          target="_blank"
          rel="noreferrer"
        >
          <span>{t("global.btn.contract")}</span>
          <LinkIcon />
        </a>

        {/* Order Terms Checkbox */}
        <div className="rules-box">
          <input
            type="checkbox"
            name="orderTermsAccepted"
            checked={formState.orderTermsAccepted}
            onChange={handleCheckboxChange("orderTermsAccepted")}
          />
          <label
            className="contractSubmit"
            onClick={handleCheckboxChange("orderTermsAccepted")}
          >
            {t("pdf.terms.declaration")} <a
              href="https://asdsystems.eu/regulamin/"
              target="_blank"
              rel="noreferrer"
            >{t("pdf.terms.orderTerms")}</a>
          </label>
        </div>
        {errors.orderTermsAccepted && (
          <Typography mt={2} className="error-message">
            {t("page.summary.validation.global.required")}
          </Typography>
        )}

        {/* Implementation Terms Checkbox */}
        <div className="rules-box">
          <input
            type="checkbox"
            name="implementationTermsAccepted"
            checked={formState.implementationTermsAccepted}
            onChange={handleCheckboxChange("implementationTermsAccepted")}
          />
          <label
            className="contractSubmit"
            onClick={handleCheckboxChange("implementationTermsAccepted")}
          >
            {t("pdf.terms.declaration")} <a
              href="https://asdsystems.eu/regulamin/"
              target="_blank"
              rel="noreferrer"
            >{t("pdf.terms.implementationTerms")}</a>
          </label>
        </div>
        {errors.implementationTermsAccepted && (
          <Typography mt={2} className="error-message">
            {t("page.summary.validation.global.required")}
          </Typography>
        )}

        {/* Delivery Terms Checkbox */}
        <div className="rules-box">
          <input
            type="checkbox"
            name="deliveryTermsAccepted"
            checked={formState.deliveryTermsAccepted}
            onChange={handleCheckboxChange("deliveryTermsAccepted")}
          />
          <label
            className="contractSubmit"
            onClick={handleCheckboxChange("deliveryTermsAccepted")}
          >
            {t("pdf.terms.declaration")} <a
              href="https://asdsystems.eu/regulamin/"
              target="_blank"
              rel="noreferrer"
            >{t("pdf.terms.deliveryTerms")}</a>
          </label>
        </div>
        {errors.deliveryTermsAccepted && (
          <Typography mt={2} className="error-message">
            {t("page.summary.validation.global.required")}
          </Typography>
        )}
      </section>

      {/* Generate PDF Button */}
      <Buttons validateForm={validateForm} handleButtonClick={handleButtonClick} />
    </div>
  );
};

export default SummaryPage;
